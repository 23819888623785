import React from 'react';
import { HashLink } from 'react-router-hash-link';

function Index() {

  return (
    <p className="" style={styles.index}>
      <HashLink smooth to={`#videos`} className="grow pill-link"><div className="">Videos</div></HashLink>
      <HashLink smooth to={`#demos`} className="grow pill-link"><div className="">Demos</div></HashLink>
      <HashLink smooth to={`#talks`} className="grow pill-link"><div className="">Talks</div></HashLink>
      <HashLink smooth to={`#books`} className="grow pill-link"><div className="">Books</div></HashLink>
      <HashLink smooth to={`#blog-posts`} className="grow pill-link"><div className="">Blog Posts</div></HashLink>
      <HashLink smooth to={`#side-projects`} className="grow pill-link"><div className="">Side Projects</div></HashLink>
      <HashLink smooth to={`#dance-moves`} className="grow pill-link"><div className="">Dance Moves</div></HashLink>
    </p>
  )
}

const styles = {
  index: {
    letterSpacing: "inherit",
    fontSize: "14px",
  }
}

export default Index;
