import React, { useEffect } from 'react';
import { BrowserRouter as Router, } from 'react-router-dom'
import './App.css';
import Shelf from './components/shelf'
import { ThemeProvider, CSSReset } from '@chakra-ui/core'
import Header from './components/header'
import AllShelves from './components/all-shelves'
import TopLink from './components/top-link'
import Footer from './components/footer'
import useWindowSize from './helpers/window-size-hook'
import WindowSizeContext from './helpers/window-size-context'
import getScreenSize from './helpers/get-screen-size'

function App() {

  const windowSize = useWindowSize();
  const styles = getStyles(getScreenSize(windowSize))

  return (
    <Router>
      <WindowSizeContext.Provider value={windowSize}>
        <ThemeProvider>
          <div className="app" style={styles.app}>
            <Header />
            <AllShelves />
            <TopLink />
            <Footer />
          </div>
        </ThemeProvider>
      </WindowSizeContext.Provider>
    </Router>
  );
}

function getStyles({ isDesktop, isTablet, isMobile }) {
  let styleMods = {};
  if (isDesktop) {
    styleMods = {
      app: {
        margin: '0px 150px',
      }
    }
  }

  if (isTablet) {
    styleMods = {
      app: {
        margin: '0px 50px',
      }
    }
  }

  if (isMobile) {
    styleMods = {
      app: {
        margin: '0px 35px',
      }
    }
  }

  let styles = {
    app: {
      minWidth: '320px',
      margin: '0px 150px',
    },
    ...styleMods
  }
  return styles;
}

export default App;
