import React, { useContext } from 'react';
import { Box, Image, Badge } from "@chakra-ui/core";
import '../App.css';
import WindowSizeContext from '../helpers/window-size-context'
import getScreenSize from '../helpers/get-screen-size'

function VideoCard(info) {
  const windowSize = useContext(WindowSizeContext)
  const styles = getStyles(getScreenSize(windowSize))

  return (
    <div key={info.key} style={styles.card} className="card hvr-grow-shadow">
      <a href={info.url}>
        <img style={styles.img} src={info.imageUrl} alt={info.title} />
      </a>
    </div>
  );
}

function getStyles({ isDesktop, isTablet, isMobile }) {
  let img, card = {};
  if (isDesktop) {
  }

  if (isTablet) {
    card = {
      height: '166px',
      marginRight: '20px',
      marginBottom: '50px',
    }
  }

  if (isMobile) {
    card = {
      height: 'inherit',
      marginRight: '0px',
      marginBottom: '50px',
    }
    img = {
      width: '100%',
    }
  }

  let styles = {
    img: {
      width: '300px',
      ...img,
    },
    card: {
      height: '166px',
      marginRight: '50px',
      marginBottom: '50px',
      ...card,
    },
  }
  return styles;
}


export default VideoCard;
