import React, { useContext } from 'react';
import '../App.css';
import Card from './card.js'
import { SimpleGrid } from "@chakra-ui/core";
import CONTENT from '../content/content.json'
import AnchorLink from './anchor-link.js';
import TopLink from './top-link.js';

function Shelf(info) {

  const items = info.items.map((itemInfo) => (
    Card(itemInfo)
  ))

  let headerId = info.title
    .replace(' ', '-')
    .toLowerCase();

  return (
    <div>
      <h3 id={headerId}
        style={styles.shelfTitle} className="heading">
        { AnchorLink(headerId) }
        { info.title }
      </h3>
      <div style={styles.shelf} key={info.title}>
        { items }
      </div>
    </div>
  );
}

const styles = {
  shelfTitle: {
    fontSize: '32px',
  },
  shelf: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  }
}

export default Shelf;
