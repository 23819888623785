import React from 'react';
import '../App.css';
import Card from './card.js'
import { SimpleGrid } from "@chakra-ui/core";
import Shelf from './shelf.js'

import CONTENT from '../content/content.json'

function AllShelves() {

  const shelves = CONTENT.map((shelf) => (
    Shelf(shelf)
  ))

  return (
    <div>
      { shelves }
    </div>
  );
}

export default AllShelves;
