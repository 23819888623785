import React, { useContext } from 'react';
import WindowSizeContext from '../helpers/window-size-context'
import getScreenSize from '../helpers/get-screen-size'
import Social from './social'
import Index from './index'

function Header(info) {

  const windowSize = useContext(WindowSizeContext)
  const styles = getStyles(getScreenSize(windowSize))

  return (
    <div style={styles.headerContainer}>
      <h1 style={styles.header}>Hi, I'm Asa</h1>
      <Social />
      <p style={styles.subHeader}>I'm a developer, designer, breakdancer, musician, and sailor ⛵️</p>
      <p style={styles.subHeader}>Check out some of my work below!</p>
      <Index />
    </div>
  )
}

function getStyles({ isDesktop, isTablet, isMobile }) {
  let styleMods = {};
  if (isDesktop) {
    styleMods = {
    }
  }

  if (isTablet) {
    styleMods = {
      headerContainer: {
        margin: "50px 0px 100px 0px",
      },
      header: {
        fontSize: "45px",
      },
      subHeader: {
        fontSize: "20px",
      },
    }
  }

  if (isMobile) {
    styleMods = {
      headerContainer: {
        margin: "20px 0px 30px 0px",
      },
      header: {
        fontSize: "45px",
      },
      subHeader: {
        fontSize: "20px",
      },
    }
  }

  let styles = {
    headerContainer: {
      margin: "100px 0px 100px 0px",
    },
    header: {
      fontSize: "55px",
    },
    subHeader: {
      fontSize: "25px",
    },
    ...styleMods
  }
  return styles;
}

export default Header;
