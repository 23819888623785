import React, { useContext } from 'react';
import { Box, Image, Badge } from "@chakra-ui/core";
import '../App.css';
import WindowSizeContext from '../helpers/window-size-context'
import getScreenSize from '../helpers/get-screen-size'

function BlogCard(info) {
  const windowSize = useContext(WindowSizeContext)
  const styles = getStyles(getScreenSize(windowSize))

  return (
    <div key={info.key} style={styles.card} className="card hvr-grow-shadow">
      <a href={info.url}>
        <img style={styles.img} src={info.imageUrl} alt={info.title} />
      </a>
      <div style={styles.footer}>
        <div style={styles.title}>{ info.title }</div>
        <div style={styles.subTitle}>{ info.subTitle }</div>
      </div>
    </div>
  );
}

function getStyles({ isDesktop, isTablet, isMobile }) {
  let styleMods = {};
  if (isDesktop) {
    styleMods = {
    }
  }

  if (isTablet) {
    styleMods = {
      card: {
        borderRadius: '15px',
        marginRight: '20px',
        marginBottom: '50px',
      }
    }
  }

  if (isMobile) {
    styleMods = {
      img: {
        width: '100%',
        borderRadius: '15px 15px 0px 0px',
      },
      card: {
        borderRadius: '15px',
        marginRight: '0px',
        marginBottom: '50px',
      }
    }
  }

  let styles = {
    img: {
      borderRadius: '15px 15px 0px 0px',
      width: '300px',
    },
    card: {
      borderRadius: '15px',
      marginRight: '50px',
      marginBottom: '50px',
      border: '1px solid rgba(0,0,0,0.2)',
    },
    footer: {
      borderTop: '1px solid rgba(0,0,0,0.2)',
      padding: '20px',
      display: 'flex',
      alignItems: 'start',
      flexDirection: 'column',
    },
    title: {
      fontSize: '18px',
      paddingBottom: '5px',
    },
    subTitle: {
      fontSize: '12px'
    },
    ...styleMods
  }
  return styles;
}

export default BlogCard;
