import React from 'react';
import Social from './social'

function Footer() {

  return (
    <div style={styles.center}>
      <Social />
    </div>
  )
}

const styles = {
  center: {
    display: "flex",
    width: "150px",
    margin: "40px auto",
    justifyContent: "space-around",
  },
}

export default Footer;
