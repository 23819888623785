import React, {useState} from 'react';
import { debounce } from "debounce";

const TopLink = () =>{

  const [showScroll, setShowScroll] = useState(false)

  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 200){
      setShowScroll(true)
    } else if (showScroll && window.pageYOffset <= 200){
      setShowScroll(false)
    }
  };

  const scrollTop = () =>{
    window.scrollTo({top: 0, behavior: 'smooth'});
    window.location.hash = "#_"
  };

  window.addEventListener('scroll', debounce(checkScrollTop, 50))

  let arrowStyles = {
    display: "none",
    position: "fixed",
    bottom: 0,
    right: 0,
    justifyContent: "center",
    alignItems: "center",
    width: '40px',
    height: '40px',
    borderRadius: "10px 10px 0px 0px",
    backgroundColor: '#ddd',
    marginRight: '5%',
  }

  if (showScroll) {
    arrowStyles.display = "flex";
  }

  return (
    <div style={arrowStyles} onClick={scrollTop}>
      <i className="fas fa-arrow-up" aria-hidden="true"></i>
    </div>
  );
}

export default TopLink;
