import React from 'react';
import { Box, Image, Badge } from "@chakra-ui/core";
import '../App.css';

import VideoCard from './card-video.js'
import BlogCard from './card-blog.js'

function Card(info) {
  const cardStyleMapping = {
    'video': VideoCard,
    'blog': BlogCard,
    'onboarding': BlogCard,
    'talk': BlogCard,
    'side-project': BlogCard,
    'dance-move': VideoCard,
  }


  const Card = cardStyleMapping[info.type]

  if (!Card) {
    console.error(`ERROR: Need to implement a card for type: ${info.type}`)
  }

  return (
    Card(info)
  )
}

export default Card;
